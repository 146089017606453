<template>
  <div class="subsales-report">
    <!-- =================== Branch Name & Export Button =================== -->
    <div class="row align-items-start p-2 mt-4">
      <div v-if="isKeyIndexLoading" class="col-12 sm-col-6">
        <h3 class="mb-2 sm-mb-0 skeleton"></h3>
        <h4 v-if="agent" class="mt-2 skeleton"></h4>
      </div>
      <div v-else class="col-12 sm-col-6">
        <h3 class="mb-2 sm-mb-0">{{ subsaleIndex.branchName }}</h3>
        <h4 v-if="agent" class="mt-2">{{ subsaleIndex.agentName }}</h4>
      </div>
      <!-- <div class="col-12 sm-col-6 d-flex justify-content-end">
        <fd-button
          class="main medium"
          :isLoading="isReportDownloading"
          loadingEnabled
          icon="fas fa-external-link-alt"
          @click="downloadPDF"
          >Export</fd-button
        >
      </div> -->
    </div>

    <component
      :is="keyIndexComponent"
      :keyIndex="subsaleIndex"
      :isLoading="isKeyIndexLoading"
      subsales
    ></component>

    <!-- ====================== Property Sales Table ===================== -->
    <h3 class="p-2 mt-4">Properties</h3>

    <filter-panel
      v-model="isFilterActive"
      bordered
      class="filter mt-2 py-1"
      @clear="clearFilter"
      @submit="filterData"
    >
      <div v-if="isFilterLoading" class="row justify-content-center p-2">
        <spinner></spinner>
      </div>
      <div class="row py-2 sm-px-2">
        <fd-date-picker
          v-model="filter['fromDate']"
          class="col-6 md-col-3 px-1 mb-2"
          label="From Date"
          name="filterFromDate"
          :max="
            filter['toDate'] === ''
              ? undefined
              : $moment(filter['toDate'])
                  .subtract(1, 'days')
                  .format('YYYY-MM-DD')
          "
          clearable
        ></fd-date-picker>
        <fd-date-picker
          v-model="filter['toDate']"
          class="col-6 md-col-3 px-1 mb-2"
          label="To Date"
          name="filterFromDate"
          :min="
            filter['fromDate'] === ''
              ? undefined
              : $moment(filter['fromDate']).add(1, 'days').format('YYYY-MM-DD')
          "
          clearable
        >
        </fd-date-picker>
        <fd-input
          v-model="filter.fromSalePrice"
          class="col-6 md-col-3 px-1 mb-2"
          label="From Sale Price"
          name="fromSalePrice"
          type="number"
        >
        </fd-input>
        <fd-input
          v-model="filter.toSalePrice"
          class="col-6 md-col-3 px-1 mb-2"
          label="To Sale Price"
          name="toSalePrice"
          type="number"
        >
        </fd-input>
        <fd-input
          v-if="branch"
          v-model="filter['agentName']"
          class="col-12 sm-col-6 md-col-3 px-1 mb-2"
          label="Agent Name"
          name="agentName"
          type="text"
        >
        </fd-input>
        <fd-input
          v-model="filter.referenceCode"
          class="col-12 sm-col-6 md-col-3 px-1 mb-2"
          label="Reference Code"
          name="referenceCode"
          type="text"
        >
        </fd-input>
        <fd-select
          v-model="filter['propertyCategory']"
          class="col-12 sm-col-6 md-col-3 px-1 mb-2"
          label="Property Category"
          :options="propertyCategoryOptions"
          @change="getFilterPropertyTypeOptions"
        >
        </fd-select>
        <fd-select
          v-model="filter['propertyType']"
          class="col-12 sm-col-6 md-col-3 px-1 mb-2"
          label="Property Type"
          :options="propertyTypeOptions"
          :disabled="$isStringEmpty(filter['propertyCategory'])"
        >
        </fd-select>
      </div>
    </filter-panel>

    <div class="card">
      <vue-good-table
        mode="remote"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        :columns="subsaleTableColumns"
        :rows="subsaleTableData"
        :totalRows="subsaleTablePagination.total"
        :isLoading="isTableLoading"
        :pagination-options="{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: perPageOptions,
          dropdownAllowAll: false
        }"
      >
        <div slot="loadingContent">
          <div class="d-flex justify-content-center">
            <spinner></spinner>
          </div>
        </div>
        <div slot="emptystate">
          <no-data message="Data is not available"></no-data>
        </div>
        <div slot="table-actions" class="p-2">
          <button
            :class="{ active: isFilterActive }"
            class="btn toggle"
            @click="filterToggle"
          >
            <i class="fas fa-filter"></i>
          </button>
        </div>
        <!-- Table Buttons -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'builtUp'">
            {{ props.row.builtUpArea }}
            {{ getAreaUnitAbbr(props.row.areaUnitType) }}
          </span>
          <span v-if="props.column.field == 'land'">
            {{ props.row.landArea }}
            {{ getAreaUnitAbbr(props.row.areaUnitType) }}</span
          >
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";
import "@/assets/GlobalStyling/static-css/skeleton.css";
import BranchKeyIndex from "@/components/SalesReport/BranchKeyIndex";
import AgentKeyIndex from "@/components/SalesReport/AgentKeyIndex";
import SalesReportAPI from "@/api/v1/salesReport";
import {
  areaUnitTypeAbbr as AREA_UNIT_ABBR,
  areaUnitType as AREA_UNIT_TYPE,
  propertyCategory as PROPERTY_CATEGORY
} from "@/enums";
import { numberWithCommas } from "@/utils/string";

export default {
  components: {
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner")
  },
  mixins: [vueGoodTable],
  props: {
    branch: {
      type: Boolean,
      default: false
    },
    agent: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      isReportDownloading: false,
      isKeyIndexLoading: false,
      subsaleIndex: {
        totalBookingFees: 0,
        totalSaleAmount: 0,
        totalSubmittedBooking: 0,
        branchName: "",
        agentName: ""
      },

      isTableLoading: false,
      subsaleTableData: [],
      subsaleTablePagination: {
        total: 0
      },
      subsaleTableColumns: [
        {
          label: "Reference Code",
          field: "referenceCode",
          sortable: false
        },
        {
          label: "Agent Name",
          field: "agentName",
          sortable: false,
          hidden: !this.branch
        },
        {
          label: "Sale Price",
          field: "salePrice",
          sortable: false,
          formatFn: (val) => numberWithCommas(Number(val).toFixed(2))
        },
        {
          label: "Property Category",
          field: "propertyCategory",
          sortable: false
        },
        {
          label: "Property Type",
          field: "propertyType",
          sortable: false
        },
        {
          label: "Build up Area",
          field: "builtUp",
          sortable: false
        },
        {
          label: "Land Area",
          field: "builtUp",
          sortable: false
        },
        {
          label: "Tenure Type",
          field: "tenureType",
          sortable: false,
          formatFn: (val) => (val.length < 1 ? "-" : val.join(", "))
        },
        {
          label: "Booking Date ",
          field: "bookingDate",
          sortable: false,
          formatFn: (val) => this.$moment(val).format("DD-MM-YYYY")
        },
        {
          label: "Booking Completed Date",
          field: "completedAt",
          sortable: false,
          formatFn: (val) => this.$moment(val).format("DD-MM-YYYY")
        }
      ],

      tableParams: {
        page: 1,
        perPage: 30
      },
      // Filter
      isFilterActive: false,
      isFilterLoading: false,
      propertyTypeOptions: [],
      propertyCategoryOptions: this.$mapJsonToArray(PROPERTY_CATEGORY, (e) => ({
        id: e,
        name: e
      })),
      filter: {
        fromDate: "",
        toDate: "",
        fromSalePrice: "",
        toSalePrice: "",
        agentName: "",
        referenceCode: "",
        propertyCategory: "",
        propertyType: ""
      }
    };
  },
  computed: {
    keyIndexComponent() {
      if (this.branch) {
        return BranchKeyIndex;
      } else if (this.agent) {
        return AgentKeyIndex;
      } else {
        return "";
      }
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await Promise.all([this.getKeyIndex(), this.getSubsales()]);
    },
    // ========================= Table related methods =========================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getSubsales();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getSubsales().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    // ========================= Filter related methods ========================
    filterData() {
      this.tableParams.page = 1;
      this.getKeyIndex();
      this.getSubsales();
    },
    clearFilter() {
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getKeyIndex();
      this.getSubsales();
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    async getFilterPropertyTypeOptions(val) {
      try {
        this.$store.commit("setIsLoading", true);
        let data = await this.$store.dispatch(
          "manageProperty/getPropertyTypes",
          { queries: { propertyCategory: val } }
        );

        this.propertyTypeOptions = this._.cloneDeep(data);

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get property type options. Please try again later."
        });
      }
    },
    // ============================== API RELATED ==============================
    async getKeyIndex() {
      try {
        this.isKeyIndexLoading = true;
        let filteredParam = this.$cleanQueryParam(this.filter);

        let getIndexAPI = this.getIndexAPI();
        let data = await getIndexAPI(this.$route.params.id, {
          queries: {
            ...filteredParam
          }
        });
        this.subsaleIndex = this._.cloneDeep(data);
        this.isKeyIndexLoading = false;
      } catch (error) {
        this.isKeyIndexLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get agency sales index. Please try again later."
        });
        throw error;
      }
    },
    async getSubsales() {
      try {
        this.isTableLoading = true;
        let filteredParam = this.$cleanQueryParam(this.filter);

        let getSubsalesAPI = this.getSubsalesAPI();
        let data = await getSubsalesAPI(this.$route.params.id, {
          queries: {
            ...filteredParam,
            page: this.tableParams.page,
            perPage: this.tableParams.perPage
          }
        });

        this.subsaleTableData = this._.cloneDeep(data.data);
        this.subsaleTablePagination = this._.cloneDeep(data.meta.pagination);

        this.isTableLoading = false;
      } catch (error) {
        this.isTableLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get branches sale data. Please try again later."
        });
        throw error;
      }
    },
    async downloadPDF() {
      try {
        this.isReportDownloading = true;
        let filteredParam = this.$cleanQueryParam(this.filter);
        // await reportAPI.downloadReport(this.$route.params.id, {
        //   queries: {
        //     ...filteredParam
        //   }
        // });
        this.isReportDownloading = false;
      } catch (error) {
        this.isReportDownloading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to export report. Please try again later."
        });
      }
    },
    getIndexAPI() {
      if (this.branch) {
        return SalesReportAPI.getBranchSubsaleIndex;
      } else if (this.agent) {
        return SalesReportAPI.getAgentSubsaleIndex;
      } else {
        throw '[SubsalesReport]: Either "branch" or "agent" is not set for component';
      }
    },
    getSubsalesAPI() {
      if (this.branch) {
        return SalesReportAPI.getBranchSubsales;
      } else if (this.agent) {
        return SalesReportAPI.getAgentSubsales;
      } else {
        throw '[SubsalesReport]: Either "branch" or "agent" is not set for component';
      }
    },
    getAreaUnitAbbr(areaUnit) {
      let key = Object.keys(AREA_UNIT_TYPE).find(
        (key) => AREA_UNIT_TYPE[key] === areaUnit
      );
      return AREA_UNIT_ABBR[key];
    }
  }
};
</script>

<style lang="scss"></style>
