<template>
  <!-- ======================== Subsales Key Index ========================= -->
  <div class="branch-key-index row">
    <template v-if="subsales">
      <sales-index-card
        :class="cardCSS"
        :isLoading="isLoading"
        titleClass="uppercase"
        title="Total Booking Fees"
        valuePrefix="RM"
        :value="numberFormat(keyIndex.totalBookingFees, { decimal: 1 })"
        :extraValue="`RM ${
          numberWithCommas(parseInt(keyIndex.totalBookingFees)) || 0
        }`"
      ></sales-index-card>
      <sales-index-card
        :class="cardCSS"
        :isLoading="isLoading"
        titleClass="uppercase"
        title="Total Sale Amount"
        valuePrefix="RM"
        :value="numberFormat(keyIndex.totalSaleAmount, { decimal: 1 })"
        :extraValue="`RM ${
          numberWithCommas(parseInt(keyIndex.totalSaleAmount)) || 0
        }`"
      >
      </sales-index-card>
      <sales-index-card
        :class="cardCSS"
        :isLoading="isLoading"
        titleClass="uppercase"
        title="Total Submitted Booking"
        :value="numberFormat(keyIndex.totalSubmittedBooking, { decimal: 1 })"
      ></sales-index-card>
    </template>

    <template v-else-if="rent">
      <!-- ========================== Rent Key Index =========================== -->
      <sales-index-card
        :class="cardCSS"
        :isLoading="isLoading"
        titleClass="uppercase"
        title="Total Rent Amount"
        valuePrefix="RM"
        :value="numberFormat(keyIndex.totalSaleAmount, { decimal: 1 })"
        :extraValue="`RM ${
          numberWithCommas(parseInt(keyIndex.totalSaleAmount)) || 0
        }`"
      >
      </sales-index-card>
      <sales-index-card
        :class="cardCSS"
        :isLoading="isLoading"
        titleClass="uppercase"
        title="Total Submitted Booking"
        :value="numberFormat(keyIndex.totalSubmittedBooking, { decimal: 1 })"
      ></sales-index-card>
    </template>

    <template v-else>Branch Key Index: Please choose a key index type</template>
  </div>
</template>

<script>
import { numberFormat, numberWithCommas } from "@/utils/string";

export default {
  components: {
    SalesIndexCard: () => import("@/components/SalesReport/SalesIndexCard")
  },
  mixins: [],
  props: {
    subsales: {
      type: Boolean,
      default: false
    },
    rent: {
      type: Boolean,
      default: true
    },
    keyIndex: {
      type: Object
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      cardCSS: "col-12 sm-col-6 md-col-3 px-1 mb-2 md-mb-0",

      numberFormat,
      numberWithCommas
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
</style>
